body {
  background-color: #070709;
  color: white;
}

.title {
  color: white;
  margin-bottom: 1 rem;
}

.box {
  margin-top: 50px;
}

.panel {
  margin-top: 2em;
}

.panel-heading {
  background-image: linear-gradient(to left, #38052f, #070709);
  background-color: black;
  color: white;
}

.address-box {
  padding: 3em;
}

.box .title {
  color: #333;
}

.navbar {
  background-color: black;
}

.navbar .navbar-item {
  color: white;
}

.connect-wallet span {
  color: #e10859;
}

.connect-wallet:hover {
  background-color: #e1e1e1 !important;
}

.container.main-content {
  max-width: 1000px !important;
}

.dapp-hero-body {
  margin-top: 100px;
}

.withdraw-error {
  color: red;
}

.withdraw-success {
  color: green;
}

/* Table styles */
table {
  border: 1px solid black;
  margin: 50px;
  border-collapse: collapse;
  font-family: sans-serif;
}

td,
th {
  border: 1px solid black;
  padding: 10px;
}

thead th {
  background-color: rgba(39, 39, 202, 0.75);
  color: white;
}

tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.055);
}

/* App.css or your custom CSS file */

.input-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing */
  align-items: center; /* Align items vertically in the center */
  margin-right: 30px; /* Adjust the margin as needed for spacing between input fields */
  max-width: 1600px; /* Adjust the maximum width as needed */
}

.input-container > div {
  flex: 1; /* Distribute available space equally between divs */
}

.custom-footer {
  font-size: 20px; /* Adjust the font size as needed */
  background-color: black;
  color: white; /* Set text color to white or any other color that suits your design */
  padding: 20px; /* Add padding to provide spacing around the content */
}

/* Gradient background */
.gradient-background {
  background: linear-gradient(to bottom, #00f, purple, red);
  position: relative;
  min-height: 100vh; /* Set a minimum height to allow scrolling */
  overflow: hidden;
}

/* Spinning logo pseudo-element */
.gradient-background::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  background: url("./images/logo.png") no-repeat;
  background-size: contain;
  animation: spin 10s linear infinite;
  top: 4.7%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
